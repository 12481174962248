.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 90px;
}

.complementary-color {
    color: #5c92bf;
}

.header-left, .header-center, .header-right {
    flex: 1;
    text-align: center;
}

.header-left {
    text-align: left;
}


.header-right {
    text-align: right;
}

.header-right a {
    text-decoration: none;
    color: inherit;
    transition: text-decoration 0.3s ease;
}

.header-right a:hover {
    text-decoration: underline;
}

@media (max-width: 800px) {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
    }
}