.about {
    font-family: "CharterRegular";
    text-align: left;
    margin: 0 auto;
    max-width: 800px;
    padding: 40px;
}

.social {
    font-family: "ApercuPro";
}
  