.circle-text-container {
    width: 130px;
    height: 130px;
    position: relative;
}

.circle-text {
    width: 100%;
    height: 100%;
    animation: rotate 15s linear infinite; /* Add animation to rotate the SVG */
}

.circle-text path {
    stroke: none;
    fill: none;
}

.circle-text text {
    font-size: 12px;
    fill: #000;
    font-weight: 500;
    font-family: "CharterRegular";
}

.complementary-color {
    color: #5c92bf;
}

.plus-sign {
    fill: #5c92bf;
    font-weight: 500;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


a {
    text-decoration: none;
    color: inherit;
}

.title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin: 0;
}